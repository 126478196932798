.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #bce4fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.transparent {
  background-color: transparent !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-caption {
  caption-side: unset !important;
}

.login-container {
  display: flex;
}

.nav-bar {
  background-color: #c10719;
}

.logged-user {
  color: #ed1c24;
}

.error {
  color: #ed1c23;
}

.z-index-0 {
  z-index: 99999;
}

.pointer {
  cursor: pointer;
}

.thumbnail {
  height: 100px;
}

.margin-top-10px {
  margin-top: 10px;
}
.text-area {
  resize: none;
}

.height-70 {
  height: 70px;
}

.table-head {
  background-color: #ed1c24;
  color: white;
}

.button-standard {
  background-color: #ed1c24;
  color: white;
}

.heading {
  color: #ed1c24;
}

.black {
  color: black;
}

.declined-meeting-tr {
  background-color: #ed1c23;
  color: #ffffff;
}

.para {
  color: #ed1c24;
}